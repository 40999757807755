.contentItem {
    padding: 0;
    margin: 0;
    width: calc(33%);
    height: 200px;
    padding: 2;
    position: relative;

    img[class~='thumb'] {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }

    div[class~='watch_soon'] {
        background-color: white;
        border-radius: 4px 0 0 0;
        position: absolute;
        right: 0px;
        top: 0px;
        border-radius: 0px 0 0 6px;
        padding: 18px;
    }

    img[class~='icon'] {
        background-color: white;
        border-radius: 4px 0 0 0;
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 24px;
        height: 24px;
        padding: 2px;
    }
}

.starContainer {
    background-color: white;
    display: flex;
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 3px;
    padding-right: 6px;
    border-radius: 0 10px 0 0;
}
