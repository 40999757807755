import { Icon } from '@assets/component/Icon/Icon';
import { StarRating } from '@assets/component/StarRating/StarRating';
import { useLists } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { ContentTypeFilter } from '../ContentTypeFilter/ContentTypeFilter';
import * as style from './ContentFilter.module.less';

export const ContentFilter = observer(() => {
    const filter = useLists().filterUI;

    return (
        <Container>
            <Row>
                <Col className="p-3">
                    <Form.Control
                        type="text"
                        placeholder="Name"
                        value={filter.search}
                        onChange={e => {
                            filter.search = e.target.value;
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="p-3">
                    <ContentTypeFilter />
                </Col>
            </Row>
            <Row>
                <Col className="p-3">
                    <StarRating
                        rating={filter.rating}
                        onChange={r => {
                            filter.rating = r;
                        }}
                    />

                    <Icon
                        className={
                            filter.show_only_not_rated ? style.active : ''
                        }
                        iconSys="no"
                        onClick={() => {
                            filter.show_only_not_rated =
                                !filter.show_only_not_rated;
                        }}
                    ></Icon>

                    <Icon
                        iconSys={
                            filter.show_watch_soon
                                ? 'heart-yes-filled'
                                : 'heart-yes'
                        }
                        onClick={() => {
                            filter.show_watch_soon = !filter.show_watch_soon;
                        }}
                    ></Icon>
                </Col>
            </Row>
        </Container>
    );
});
