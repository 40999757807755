const _temp0 = require("../../../icons/system/back.svg");
const _temp1 = require("../../../icons/system/camera.svg");
const _temp2 = require("../../../icons/system/content.svg");
const _temp3 = require("../../../icons/system/flip-camera.svg");
const _temp4 = require("../../../icons/system/heart-yes-filled.svg");
const _temp5 = require("../../../icons/system/heart-yes.svg");
const _temp6 = require("../../../icons/system/letter-t.svg");
const _temp7 = require("../../../icons/system/loading.svg");
const _temp8 = require("../../../icons/system/menu.svg");
const _temp9 = require("../../../icons/system/no.svg");
const _temp10 = require("../../../icons/system/plus.svg");
const _temp11 = require("../../../icons/system/record.svg");
const _temp12 = require("../../../icons/system/saved.svg");
const _temp13 = require("../../../icons/system/star.svg");
const _temp14 = require("../../../icons/system/stop-record.svg");
const _temp15 = require("../../../icons/system/upload.svg");
const _temp16 = require("../../../icons/system/yes.svg");
module.exports = {
  "back": _temp0,
  "camera": _temp1,
  "content": _temp2,
  "flip-camera": _temp3,
  "heart-yes-filled": _temp4,
  "heart-yes": _temp5,
  "letter-t": _temp6,
  "loading": _temp7,
  "menu": _temp8,
  "no": _temp9,
  "plus": _temp10,
  "record": _temp11,
  "saved": _temp12,
  "star": _temp13,
  "stop-record": _temp14,
  "upload": _temp15,
  "yes": _temp16
}