import { Icon } from '@assets/component/Icon/Icon';
import { useAssets, useLists, useToaster } from '@assets/model/Store';
import {
    ContentCategory,
    ListContentEntry
} from '@assets/model/stores/Data/Lists/ListsStore';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import * as style from './ContentPreview.module.less';

interface IContentPreviewProps {
    name: string;
    contentType: ContentCategory;
}

export const ContentPreview = observer((props: IContentPreviewProps) => {
    const [content, setContent] = useState(new ListContentEntry());
    const toaster = useToaster();
    const lists = useLists();
    const assets = useAssets();
    const [didSave, setDidSave] = useState(false);

    useEffect(() => {
        const contentId = lists.categories.find(
            c => c.name == props.contentType
        );
        content.name = props.name;
        content.categoryId = contentId.id;
        content.tryToEnhance();
    }, [content]);

    const icon = assets.getContentIconUrl(content.category);

    return (
        <tr key={content.name}>
            <td>
                <Table className={style.entry}>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    width: 48,
                                    paddingRight: 2
                                }}
                            >
                                <Icon iconSys={'content'} size="S"></Icon>
                            </td>
                            <td
                                style={{
                                    width: '100%'
                                }}
                            >
                                {content.name}
                            </td>
                            <td
                                style={{
                                    width: 48
                                }}
                            >
                                <Icon
                                    iconSys="plus"
                                    size="S"
                                    onClick={() => {
                                        if (!didSave) {
                                            content.save();
                                            setDidSave(true);

                                            toaster.addMessage(
                                                `> Content Added: ${props.name} / ${props.contentType}`
                                            );
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className={style.image_preview} colSpan={3}>
                                <img src={content.thumbnailURL} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>{content.overview}</td>
                        </tr>
                    </tbody>
                </Table>
            </td>
        </tr>
    );
});
