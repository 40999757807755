import * as taskIconsFs from '/src/icons/*.svg';
import * as docIconsFs from '/src/icons/docs/*.svg';

import * as contentIcons from '/src/icons/content/*.svg';
import * as systemIcons from '/src/icons/system/*.svg';

export type SysIcons = "yes" | "no" | "back" | 'saved' | 'record' | 'stop-record' | 'letter-t' | 'content' | 'plus' | 'camera' | 'upload' | 'flip-camera' | 'star' | 'menu' | 'heart-yes' | 'heart-yes-filled';

interface Icon {
	name: string;
	url: string;
}

interface IconsLookup {
	[key: string]: Icon;
}

export class AssetsStore {
	taskIcons: IconsLookup = {};
	docIcons: IconsLookup = {};
	contentIcons: IconsLookup = {};
	sysIcons: IconsLookup = {};

	constructor() {
		this.setIcons();
	}

	private getIconsAsList(entries: any): IconsLookup {
		var l = {};
		Object.keys(entries).forEach((name) => {
			l[name] = {
				name: name,
				url: entries[name]
			}
		})
		return l;
	}


	private setIcons() {
		this.taskIcons = this.getIconsAsList(taskIconsFs);
		this.docIcons = this.getIconsAsList(docIconsFs);
		this.contentIcons = this.getIconsAsList(contentIcons);
		this.sysIcons = this.getIconsAsList(systemIcons);
	}

	getTaskIconUrl(iconName: string): string {
		let icon = iconName == "" ? "default" : iconName;
		try {
			return this.taskIcons[icon].url;
		} catch {
			return this.taskIcons["default"].url;
		}
	}

	getContentIconUrl(contentType: string): string {
		if (this.contentIcons[contentType] == undefined) {
			return this.contentIcons["default"].url;
		}

		return this.contentIcons[contentType].url;
	}
}
